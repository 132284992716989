import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import "./css/app.scss";

import("./js/App");

window.axios = axios;
window.axios.defaults.withCredentials = true;

window.myMapObject = (el, callbackFunc) => {
    if (!el) return [];

    if (typeof el == "object") {
        return Object.keys(el).map((key) => {
            return callbackFunc(el[key], key);
        });
    }

    let index = -1;
    return el.map((el_) => {
        index++;
        return callbackFunc(el_, index);
    });
};

/**
 * Formatea un número y opcionalmente adiciona un prefijo
 * @param  {[type]} number  Número a formatear
 * @param  {[type]} prefix  Prefijo que se adiciona
 */
window.numberFormat = (number, prefix = null) => {
    return prefix
        ? prefix + new Intl.NumberFormat().format(number)
        : new Intl.NumberFormat().format(number);
};

/**
 * Convierte un número en el formato requerido para venta
 * @param {Numeric} price Número a converir
 */
window.convertSaleType = (price) => {
    //return parseFloat(price).toFixed(2);
    return parseInt(price);
};

// If you want to start measuring performance in your session, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
